<template>
  <div id="divContactUsWrapper">
    <VueWrapperNarrow class="wrapper-narrow">
      <div class="co-wrapper" v-html="content"></div>
    </VueWrapperNarrow>
  </div>
</template>
<script>
import StaticContent from '@/services/Api/staticContents';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';

export default {
  name: 'ContactUs',
  components: { VueWrapperNarrow },
  data() {
    return {
      content: '',
    };
  },
  created() {
    StaticContent.getStaticContent('BizeUlasin').then(res => {
      if (res.data.Data) {
        this.content = res.data.Data.content;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.wrapper-narrow {
  padding-top: palette-space-level('60');
}

.co-wrapper {
  text-align: left;
}
</style>
